import { useState } from "react";
import { Routes, Route } from "react-router-dom";
import { Box, colors, CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, tokens, useMode } from "./theme";
import Calendar from "./scenes/calendar/calendar";
import Sidebar from "./scenes/global/Sidebar";
import Dashboard from "./scenes/dashboard";
import Home from "./scenes/home";
import Topbar from "./scenes/global/Topbar";
import Login from "./login/Login";
import { auth } from "./firebase-config";
import { onAuthStateChanged, signOut } from "firebase/auth";
import { useMemo } from "react";
import Users from "./scenes/users";
import Trending from "./scenes/trending";
import Sticker from "./scenes/stickers";
import Banner from "./scenes/banner";
import EditCategory from "./scenes/manageCategory";
import Background from "./backgroundOfWebsite";
import Notification from "./scenes/notifications";
import OutletManage from "./scenes/manageOutlet";
import ManageCustom from "./scenes/manageCustom";
import Search from "./scenes/search";
import Video from "./scenes/video";
import VideoCategory from "./scenes/addVideoCategory";
import ManageVideoCategory from "./scenes/manageVideoCategory";
import General from "./scenes/general";
import Menu from "./scenes/menu";
import ManageStickers from "./scenes/stickers/stickerCategory";
import Frames from "./scenes/frames/frame";
import More from "./scenes/more";
import Testimonials from "./scenes/testimonials";
import ManageAnyCategory from "./scenes/manageAnyCategory";
import TestiComboEventsWallPos from "./scenes/testimonials";
import Sales from "./scenes/sales";
import Offers from "./scenes/offer";
import Filter from "./scenes/filter";
import NewFilterAdd from "./scenes/filter/newfilter";
import EditFilter from "./scenes/filter/editFilter";
import AddImagesInFilter from "./scenes/filter/addImagesInFilter";
import Analytics from "./scenes/analytics";
import DownloadedImages from "./scenes/downloadedImages";
import TeamDashboard from "./scenes/dashboard/team_Dashboard";
import TeamSidebar from "./scenes/global/TeamSidebar";
import MoreTeam from "./scenes/more/moreForTeam";
import Coupens from "./scenes/coupens/index.jsx";
import VideoCategoryDownloadList from "./scenes/videoDownloads/index.jsx";
import PopUpScreen from "./scenes/popup/index.jsx";
import Combos from "./scenes/combos/index.jsx";

function App() {
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);
  const colors = tokens(theme.palette.mode);

  const [email, setEmail] = useState("");
  const [start, setStart] = useState(false);
  const [noLogin, setNoLogin] = useState("no");
  onAuthStateChanged(auth, (currentUser) => {
    try {
      if (!start){
        
        setEmail(currentUser.email);
        setStart(true);
      }
    } catch (error) {
      setNoLogin("yes");
    }
  });
  if (email == "vinaydsoni822@gmail.com" || email == "nishith.soni@gmail.com" ) { 
    return (
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Topbar></Topbar>
          <Background></Background>
          <Box className="app">
            <Sidebar isSidebar={isSidebar} />
            <main className="content">  
              
              <Routes>
                <Route exact path="/" element={<Dashboard />} />
                <Route exact path="/home" element={<Home />} />
                <Route exact path="/general" element={<General />} />
                <Route exact path="/video" element={<Video />} />
                <Route exact path="/analytics" element={<Analytics />} />
                <Route exact path="/users" element={<Users />} />
                <Route exact path="/downloads" element={<DownloadedImages />} />
                <Route exact path="/videodownloads" element={<VideoCategoryDownloadList />} />
                <Route exact path="/trending" element={<Trending />} />
                <Route exact path="/gentrending" element={<Trending />} />
                <Route exact path="/morecuisines" element={<Trending />} />
                <Route exact path="/genmorecuisines" element={<Trending />} />
                <Route exact path="/stickers" element={<TestiComboEventsWallPos />} />
                <Route exact path="/foodImages" element={<TestiComboEventsWallPos />} />
                <Route exact path="/background" element={<TestiComboEventsWallPos />} />
                <Route exact path="/frame" element={<Frames />} />
                <Route exact path="/framevideo" element={<Frames />} />
                <Route exact path="/menu" element={<Menu />} />
                <Route exact path="/offers" element={<Offers />} />
                <Route exact path="/popup" element={<PopUpScreen />} />
                {/* <Route exact path="/testimonials" element={<TestiComboEventsWallPos />} /> */}
                <Route exact path="/testimonials" element={<General />} />
                <Route exact path="/wallposter" element={<General />} />
                <Route exact path="/flyer" element={<General />} />
                {/* <Route exact path="/wallposter" element={<TestiComboEventsWallPos />} /> */}
                <Route exact path="/combos" element={<Combos />} />
                {/* <Route exact path="/combos" element={<TestiComboEventsWallPos />} /> */}
                <Route exact path="/events" element={<General />} />
                {/* <Route exact path="/events" element={<TestiComboEventsWallPos />} /> */}
                <Route exact path="/brands" element={<TestiComboEventsWallPos />} />
                <Route exact path="/more" element={<More />} />
                <Route exact path="/sales" element={<Sales />} />
                <Route exact path="/coupons" element={<Coupens />} />
                {/* <Route exact path="/search" element={<Search />} /> */}
                <Route exact path="/banner" element={<Banner/>} />
                <Route exact path="/genbanner" element={<Banner/>} />
                <Route exact path="/menubanner" element={<Banner/>} />
                <Route exact path="/videobanner" element={<Banner/>} />
                <Route exact path="/notification" element={<Notification/>} />
                <Route path="home/edit/category/:name" element={<EditCategory/>} />
                <Route path="filter/category/:name" element={<Filter/>} />
                <Route path="filter/new/category/:type/:key/:name" element={<NewFilterAdd/>} />
                <Route path="filter/edit/category/:catKey/:type/:filterKey" element={<EditFilter/>} />
                <Route path="filter/edit/addimages/category/:catKey/:type/:filterKey/:name" element={<AddImagesInFilter/>} />
                <Route path="menu/edit/category/:name" element={<OutletManage/>} />
                <Route path="general/edit/category/:name" element={<OutletManage/>} />
                <Route path="video/edit/category/:name" element={<ManageVideoCategory/>} />
                <Route path="home/edit/outlet/:name" element={<OutletManage/>} />
                <Route path="stickers/edit/:name" element={<ManageAnyCategory/>} />
                <Route path="background/edit/:name" element={<ManageAnyCategory/>} />
                <Route path="testimonials/edit/category/:name" element={<OutletManage/>} />
                {/* <Route path="testimonials/edit/:name" element={<ManageAnyCategory/>} /> */}
                <Route path="wallposter/edit/category/:name" element={<OutletManage/>} />
                <Route path="flyer/edit/category/:name" element={<OutletManage/>} />
                {/* <Route path="wallposter/edit/:name" element={<ManageAnyCategory/>} /> */}
                <Route path="combos/edit/category/:name" element={<EditCategory/>} />
                {/* <Route path="combos/edit/:name" element={<ManageAnyCategory/>} /> */}
                <Route path="events/edit/category/:name" element={<OutletManage/>} />
                {/* <Route path="events/edit/:name" element={<ManageAnyCategory/>} /> */}
                <Route path="foodImages/edit/:name" element={<ManageAnyCategory/>} />
                <Route path="brands/edit/:name" element={<ManageAnyCategory/>} />
                <Route path="home/edit/custom/:name" element={<EditCategory/>} />
              </Routes>
            </main>
          </Box>
        </ThemeProvider>
      </ColorModeContext.Provider>
    );
  } 
  else if (email == "team@brandbite.app" ) { 
    return (
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Topbar></Topbar>
          <Background></Background>
          <Box className="app">
            <TeamSidebar isSidebar={isSidebar} />
            <main className="content">  
              
              <Routes>
                <Route exact path="/" element={<TeamDashboard />} />
                <Route exact path="/home" element={<Home />} />
                <Route exact path="/general" element={<General />} />
                <Route exact path="/video" element={<Video />} />
                <Route exact path="/trending" element={<Trending />} />
                <Route exact path="/gentrending" element={<Trending />} />
                <Route exact path="/morecuisines" element={<Trending />} />
                <Route exact path="/genmorecuisines" element={<Trending />} />
                <Route exact path="/stickers" element={<TestiComboEventsWallPos />} />
                <Route exact path="/foodImages" element={<TestiComboEventsWallPos />} />
                <Route exact path="/background" element={<TestiComboEventsWallPos />} />
                <Route exact path="/frame" element={<Frames />} />
                <Route exact path="/framevideo" element={<Frames />} />
                <Route exact path="/menu" element={<Menu />} />
                <Route exact path="/testimonials" element={<General />} />
                <Route exact path="/wallposter" element={<General />} />
                <Route exact path="/combos" element={<Combos />} />
                <Route exact path="/events" element={<General />} />
                <Route exact path="/brands" element={<TestiComboEventsWallPos />} />
                <Route exact path="/more" element={<MoreTeam />} />
                <Route exact path="/flyer" element={<General />} />
                {/* <Route exact path="/search" element={<Search />} /> */}
                <Route exact path="/banner" element={<Banner/>} />
                <Route exact path="/genbanner" element={<Banner/>} />
                <Route exact path="/menubanner" element={<Banner/>} />
                <Route exact path="/videobanner" element={<Banner/>} />
                <Route path="home/edit/category/:name" element={<EditCategory/>} />
                <Route path="filter/category/:name" element={<Filter/>} />
                <Route path="filter/new/category/:type/:key/:name" element={<NewFilterAdd/>} />
                <Route path="filter/edit/category/:catKey/:type/:filterKey" element={<EditFilter/>} />
                <Route path="filter/edit/addimages/category/:catKey/:type/:filterKey/:name" element={<AddImagesInFilter/>} />
                <Route path="menu/edit/category/:name" element={<OutletManage/>} />
                <Route path="general/edit/category/:name" element={<OutletManage/>} />
                <Route path="video/edit/category/:name" element={<ManageVideoCategory/>} />
                <Route path="home/edit/outlet/:name" element={<OutletManage/>} />
                <Route path="stickers/edit/:name" element={<ManageAnyCategory/>} />
                <Route path="background/edit/:name" element={<ManageAnyCategory/>} />
                <Route path="testimonials/edit/category/:name" element={<OutletManage/>} />
                <Route path="wallposter/edit/category/:name" element={<OutletManage/>} />
                <Route path="flyer/edit/category/:name" element={<OutletManage/>} />
                <Route path="combos/edit/category/:name" element={<EditCategory/>} />
                <Route path="events/edit/category/:name" element={<OutletManage/>} />
                <Route path="foodImages/edit/:name" element={<ManageAnyCategory/>} />
                <Route path="brands/edit/:name" element={<ManageAnyCategory/>} />
                <Route path="home/edit/custom/:name" element={<EditCategory/>} />
              </Routes>
            </main>
          </Box>
        </ThemeProvider>
      </ColorModeContext.Provider>
    );
  }
  else if (noLogin == "yes") {
    return (
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Box className="app"> 
            <main className="content">
              <Login />
            </main>
          </Box>
        </ThemeProvider>
      </ColorModeContext.Provider>
    );
  }
}

export default App;
