/*

This is multipurpose file not only for trending, It uused fir Trending, Explore More Cuisines Home, Explore More Cuisines General,

Ignore Variable name used as trending because it used for above functions

*/


import { AddCircleOutlineRounded } from '@mui/icons-material'
import { Avatar, Button, ListItem, ListItemText, Typography, useTheme, TextField } from '@mui/material'
import { Box } from '@mui/system'
import { onValue, ref } from 'firebase/database'
import React, { useState } from 'react'
import { useMemo, useEffect } from 'react'
import Header from '../../components/Header'
import { database, storage, writedb } from '../../firebase-config'
import { tokens } from '../../theme'
import AddNewTrending from './addTrending'
import EditTrending from './editTrending'
import { useLocation, useParams } from 'react-router-dom'
const Trending = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const location = useLocation().pathname;
    const [path, setPath] = useState("");
    const [title,setTitle] = useState('');
    useMemo(() => {
        if (location === "/trending") {
            setPath("home/trending");
            setTitle("Trending")
        } else if (location === "/morecuisines") {
            setPath("home/moreCuisines");
            setTitle("More Cuisines");
        }else if (location === "/genmorecuisines"){
            setPath("general/moreCuisines");
            setTitle("More Cuisines - General");
        }else if (location === "/gentrending"){
            setPath("general/trending");
            setTitle("Trending - General");
        }
    },[path.length])
    

    const [addNew, setAddnew] = useState(false);
    const [trendingList, setTrendingList] = useState([]);
    const [positionNo, setPositionNo] = useState(0); // set position no of to move trending
    const [myPos, setMyPos] = useState(-1) // used to handle change position click event
    const [editTrendingKey, setEditTrendingKey] = useState("");
    const [editTrend, setEditTrend] = useState(false); // to display edit trend box
    const [editTrendData, setEditTrendData] = useState(); // to pass data of trending to be edit
    const trendingDisplay = () => {
        setAddnew(addNew ? false : true);
    }

    

    const editTrendingDisplay = () => {
        setEditTrend(!editTrend)
    }


    function loadTrendingFromServer() {
        if (path.length > 0) {
            myPos < 0 && (onValue(ref(database, path), (snapshot) => {
                if (myPos < 0 && positionNo == 0) {
                    trendingList.length = 0;
                    var arr = [];
                    snapshot.forEach(snapshot => {
                        const data = snapshot.val();
                        arr.push({ image: data.image, key: data.key, trigger: data.trigger, trendkey: snapshot.key, trendingName: data.name });
                    })
                    setTrendingList(arr.reverse());
                }
            }))
        }
    }

    useEffect(() => {
        loadTrendingFromServer();
    }, [trendingList.length])


    const inputStyle = {
        '& label.Mui-focused': {
            color: 'aqua',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'white',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'gray',
            },
            '&:hover fieldset': {
                borderColor: 'white',
            },
            '&.Mui-focused fieldset': {
                borderColor: 'rgb(155,155,255)',
            }, '& .MuiInputBase-input': {
                color: 'white',
                fontSize: "16px",

            },
        }
    }




    const settingMyPos = (index) => {
        setMyPos(index)
    }




    const swapData = () => {  // This is used to change position of trending
        if (positionNo < 1 || positionNo > trendingList.length || positionNo - 1 == myPos) {
            alert("Invalid Value");
            return;
        }
        else {
            const secpos = positionNo - 1;
           
            // const first = Object.assign({}, trendingList[myPos]);
            // const second = Object.assign({}, trendingList[secpos]);
            // delete first.index;
            // delete second.index;

            const firstkey = JSON.parse(JSON.stringify(trendingList[myPos].trendkey));
            const secondkey = JSON.parse(JSON.stringify(trendingList[secpos].trendkey));

            let firstSnapshot;
            let secondSnapshot;

            // delete first.trendkey;
            // delete second.trendkey;

            writedb.ref(`${path}/${firstkey}`).once('value').then((snapshot) => {
                firstSnapshot = snapshot.val();
                writedb.ref(`${path}/${secondkey}`).once('value').then((snapshot) => {
                    secondSnapshot = snapshot.val();
                    writedb.ref(`${path}/${firstkey}`).update(secondSnapshot).then(() => { 
                        writedb.ref(`${path}/${secondkey}`).update(firstSnapshot).then(() => {
                            setMyPos(-1);
                            setPositionNo(0);
                            // alert("Moved Successfully");
                        });
                    })
                })
            })

            // writedb.ref(`${path}/${firstkey}`).update(second).then(() => {
            //     writedb.ref(`${path}/${secondkey}`).update(first).then(() => {
            //         setMyPos(-1);
            //         setPositionNo(0)
            //         // loadTrendingFromServer();
            //         alert("Trending Moved Successfully");
            //     })
            // })

        }
    }


    const deleteTrending =async (data) => {
        const key = JSON.parse(JSON.stringify(data.trendkey));
        try{
            let link = await storage.ref(`${path}/${key}/image`).getDownloadURL();
            await storage.refFromURL(link).delete();
        }catch(error){}
        writedb.ref(`${path}/${key}`).remove().then(() => {
            alert("Successfully deleted");
        })
        
    }


    const editTrending = (data) => {
        const key = JSON.parse(JSON.stringify(data.trendkey));
        const dataTrend = Object.assign({}, data);
        setEditTrendingKey(key);
        setEditTrendData(dataTrend)
        setEditTrend(true)


    }



    return (
        <Box display="flex" flexDirection="column" m="20px" marginBottom="20px" maxWidth="100%" overflow="auto">
            <Box width="auto" marginBottom="15px">
                <Header title={title} subtitle="Brand Bite" />
            </Box>

            <Box marginBottom="15px" >
                <Box display="inline-block" className="thisisbtn" backgroundColor={colors.greenAccent[800]} borderRadius="20px" justifyContent="center" alignItems="center" onClick={() => { addNew ? setAddnew(false) : setAddnew(true) }} >
                    <Box display="flex" padding="7px" className='thisisbtn' justifyContent="center" alignItems="center">
                        <AddCircleOutlineRounded sx={{ marginRight: "5px", marginLeft: "3px" }} />
                        <Typography marginRight="5px" fontSize="20px">Add New</Typography>
                    </Box>
                </Box>
            </Box>


            {addNew ? <AddNewTrending trendingDisp={trendingDisplay} address={path} title={title}/> : ""}
            {editTrend ? <EditTrending trendingDisp={editTrendingDisplay} data={editTrendData} title={title} address={path} /> : ""}


            <Box minWidth="0" marginBottom="20px" overflow="auto" maxHeight="85vh" sx={{ background: colors.primary[400] }} borderRadius="10px">

                <Box display="flex" marginTop="10px" flexDirection="column" maxWidth="100%" justifyContent="start" alignItems="center" overflow="auto">
                    {
                        trendingList.map((val, index) => (
                            <React.Fragment key={index} >
                                <Box display="flex" alignItems="center" >

                                    <Box marginRight="50px">
                                        <Typography fontSize="25px">{index + 1}</Typography>
                                    </Box>
                                    <Box marginRight="50px" marginTop="20px">
                                        <Avatar src={val.image} sx={{ width: 120, height: 120, marginRight: "15px", marginBottom: "10px", borderRadius: "5%", cursor: "pointer", '& > img': { objectFit: "fill", width: "100%", height: "100%" } }}>
                                        </Avatar>
                                    </Box>
                                    <Box display="flex" flexDirection="column" marginRight="50px" width="150px">
                                        <Box>
                                            <Typography fontSize="20px" noWrap>{"Trigger: " + val.trigger}</Typography>
                                        </Box>
                                        <Box display="flex" justifyContent="center">
                                            <Typography fontSize="20px" >{val.name}</Typography>
                                        </Box>
                                    </Box>
                                    <Box display={myPos == index ? "none" : "flex"} className="thisisbtn" borderRadius="10px" backgroundColor={colors.greenAccent[800]} padding="0 10px" marginRight="50px" nowrap="true" onClick={() => { settingMyPos(index) }} >
                                        <Typography fontSize="20px" noWrap>Change Position</Typography>
                                    </Box>
                                    <Box display={myPos == index ? "flex" : "none"} marginRight="50px" justifyContent="center" alignItems="center" flexDirection="column">
                                        {/* <Box className="flex-col aijc " mb="40px" ml="100px" position="absolute" justifyContent="start">
                                        <Typography  marginBottom="2%">Max</Typography>
                                        <Typography>{trendingList.length}</Typography>
                                    </Box> */}

                                        <TextField autoFocus autoComplete="off" type="number" label="Position" value={positionNo} onChange={(event) => {
                                            setPositionNo(event.target.value)
                                        }} sx={inputStyle} />
                                        <Box display="flex">
                                            <Box display="flex" className="thisisbtn" mt="10px" justifyContent="center" alignItems="center" onClick={() => { setMyPos(-1) }} backgroundColor={colors.greenAccent[800]} borderRadius="10px" padding="5px 10px" marginRight="10px">
                                                <Button><Typography textTransform="none" color="white">Cancel</Typography></Button>
                                            </Box>
                                            <Box display="flex" onClick={swapData} className="thisisbtn" mt="10px" justifyContent="center" alignItems="center" backgroundColor={colors.greenAccent[800]} borderRadius="10px" padding="5px 10px">
                                                <Button><Typography color="white" textTransform="none">Move</Typography></Button>
                                            </Box>
                                        </Box>

                                    </Box>



                                    <Box backgroundColor={colors.greenAccent[800]} className="thisisbtn" borderRadius="10px" padding="0 10px" marginRight="50px" onClick={() => { editTrending(val) }}>
                                        <Typography fontSize="20px" noWrap>Edit</Typography>
                                    </Box>
                                    <Box backgroundColor={colors.greenAccent[800]} className="thisisbtn" borderRadius="10px" padding="0 10px" marginRight="50px" onClick={() => { deleteTrending(val) }}>
                                        <Typography fontSize="20px" noWrap>Delete</Typography>
                                    </Box>
                                </Box>
                            </React.Fragment>
                        ))
                    }
                </Box>
            </Box>




        </Box>





    )
}

export default Trending;
